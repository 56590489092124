/* eslint-disable */
/* prettier-ignore-start */
import {useCallback, forwardRef, useEffect, useRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import ReactSlick from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {IcChevronLeft} from '../../Atoms/Icons';
import {isInStudio} from '../../../../lib/plasmic-utils'

const arrowVariants = {
  left: 'left-0',
  right: 'right-0 rotate-180',
};

function Arrow({onClick, direction}) {
  return (
    <div
      className={`absolute opacity-50 group-hover:opacity-100 top-0 z-[1] flex h-full w-[10%] bg-gradient-to-r from-[rgba(0,0,0,.4)] to-transparent 3xl:rounded-l-[20px] ${arrowVariants[direction]}`}
    >
      <button
        type="button"
        className="m-auto ml-[24px] flex h-[40px] w-[40px] transform rounded-full bg-white"
        onClick={onClick}
        data-testid={direction === 'left' ? 'prev-button' : 'next-button'}
      >
        <IcChevronLeft className="m-auto h-8 w-8" />
      </button>
    </div>
  );
}
const arrowProps = {
  types: {
    onClick: PropTypes.func,
    direction: PropTypes.oneOf(['left', 'right']),
  },
  defaults: {
    onClick: () => {},
    direction: 'left',
  },
};
Arrow.propTypes = arrowProps.types;
Arrow.defaultProps = arrowProps.defaults;

const HeroSlider = forwardRef(({className, studioStyles, setControlContextData, editingSlide, ...props}, ref) => {
  const [isClient, setIsClient] = useState(false);
  // this should be done with responsive css
  // the previous JS was casuing hyration issues with React SSR
  const centerPadding = '8.25%';
  if (!studioStyles) {
    className = 'group mx-auto mb-[30px] w-full max-w-full 3xl:max-w-[1540px]';
  } else {
    className = `${className} group mb-[30px]`;
  }
  setControlContextData?.({editingSlide});

  const slickRef = useRef(null);

  useImperativeHandle(ref, () => slickRef.current);

  const appendDots = useCallback(dots => {
    return (
      <div>
        <ul className="absolute bottom-0 left-0 right-0 flex flex-row justify-center px-0">
          {dots}
        </ul>
      </div>
    );
  }, []);
  const customPaging = useCallback(i => {
    return (
      <button type="button" className="before:!text-primary-800">
        {i}
      </button>
    );
  }, []);
  useEffect(() => {
    if (editingSlide !== undefined) {
      slickRef.current?.slickGoTo(editingSlide);
    }
  }, [editingSlide]);
  useEffect(() => {
    setIsClient(true);
  }, []);

  return (isClient || isInStudio()) ?  (
    <ReactSlick
      dots
      centerPadding={centerPadding}
      centerMode={true}
      nextArrow={<Arrow direction="right" />}
      prevArrow={<Arrow direction="left" />}
      appendDots={appendDots}
      customPaging={customPaging}
      ref={slickRef}
      autoplay={isInStudio() || true}
      autoplaySpeed={7000}
      focusOnSelect={isInStudio() || true}
      {...props}
      className={className}
    />
  ) : (
    <div {...props} />
  );
});
HeroSlider.displayName = 'HeroSlider';
HeroSlider.propTypes = {
  setControlContextData: PropTypes.func,
  editingSlide: PropTypes.number,
  className: PropTypes.string,
  studioStyles: PropTypes.bool,
};
HeroSlider.defaultProps = {
  setControlContextData: undefined,
  editingSlide: undefined,
  className: '',
  studioStyles: false,
};

export default HeroSlider;

export const PlasmicData = {
  name: 'HeroSlider',
  props: {
    children: {
      type: 'slot',
    },
    studioStyles: {
      type: 'boolean',
    },
    editingSlide: {
      displayName: 'Currently edited slide',
      type: 'number',
      description:
        'Switch to the specified slide (first is 0). Only affects the editor, not the final page.',
      defaultValueHint: 0,
      editOnly: true,
      hidden: () => true,
    },
  },
  actions: [
    {
      type: 'custom-action',
      control({componentProps, studioOps}) {
        const slidesCnt = componentProps.children.length;
        const editingSlide = componentProps.editingSlide ?? 0;
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              justifyContent: 'space-between',
            }}
          >
            <button
              type="button"
              style={{width: '100%'}}
              onClick={() => {
                const prevSlide = (editingSlide - 1 + slidesCnt) % slidesCnt;
                studioOps.updateProps({editingSlide: prevSlide});
              }}
            >
              Prev slide
            </button>
            <button
              type="button"
              style={{width: '100%'}}
              onClick={() => {
                const nextSlide = (editingSlide + 1) % slidesCnt;
                studioOps.updateProps({editingSlide: nextSlide});
              }}
            >
              Next slide
            </button>
          </div>
        );
      },
    },
  ],
  importPath: './components/UI/Molecules/HeroSlider',
  isDefaultExport: true,
};
